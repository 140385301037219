import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import {apiUrl} from "../../environment";
import {Dojo} from "../../shared/models/dojo";

export const fetchDojoAction = createAsyncThunk('dojo/get-one', async (payload: { link: string }) => {
    const response = await axios.get<Dojo>(`${apiUrl}/dojo/${payload.link}`);
    return response.data;
})

export interface DojoState {
    dojo?: Dojo;
    status: string;
}

const initialState: DojoState = {
    status: 'idle'
};

export const dojoSlice = createSlice({
        name: 'dojo',
        initialState,
        reducers: {
            resetDojoState: (state, action: PayloadAction) => {
                state.dojo = initialState.dojo;
                state.status = initialState.status;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(fetchDojoAction.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(fetchDojoAction.fulfilled, (state, action) => {
                    state.dojo = action.payload;
                    state.status = 'succeeded';
                })
                .addCase(fetchDojoAction.rejected, (state, action) => {
                    state.status = 'failed';
                })
        }
    }
);

export const {resetDojoState} = dojoSlice.actions;
