import React, {FormEvent, useState} from 'react';
import './InputMultiselect.scss';
import {Choice, FormInput} from "../../shared/form/Form";

interface MultiselectProps extends FormInput<Choice[]> {
    label: string;
    name: string;
    placeholder?: string;
    choices: Choice[];
}

export const InputMultiselect = ({
                                     name,
                                     label,
                                     placeholder = '',
                                     choices,
                                     onValueChanged,
                                     resetFunctionRegistar,
                                     populateFunctionRegistar,
                                     errors
                                 }: MultiselectProps) => {

    const [multiselectItems, setMultiselectItems] = useState<Choice[]>(choices);
    const [selected, setSelected] = useState<Choice[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [inputValue, setInputValue] = useState('');

    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const inputIconRef = React.useRef<HTMLDivElement>(null);
    const selectionsRef = React.useRef<HTMLDivElement>(null);
    const listRef = React.useRef<HTMLUListElement>(null);

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, [inputRef, inputValue, cursorPosition]);

    React.useEffect(() => {
        function handleClickOutside(event: any) {
            const target = event.target as Node;
            if (!inputIconRef.current?.contains(target) && !dropdownRef.current?.contains(target)) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, [dropdownRef]);

    React.useEffect(() => {
        if (resetFunctionRegistar) resetFunctionRegistar(name, reset);
        if (populateFunctionRegistar) populateFunctionRegistar(name, populate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function populate(choices: Choice[]) {
        if (choices) {
            setSelected(choices);
            choices.forEach(c => markSelected(c));
        }
    }

    function reset() {
        setMultiselectItems(choices);
        setSelected([]);
        setIsDropdownOpen(false);
        setCursorPosition(0);
        setInputValue('');
    }

    function filterMultiselectItems(prefix: string) {
        const suggestedChoices = choices.filter(c => c.name.toLowerCase().startsWith(prefix.toLowerCase()));
        setMultiselectItems(suggestedChoices);
        return !!suggestedChoices.length;
    }

    function deselectChoice(choice: Choice) {
        const choices = selected.filter(c => c.value !== choice.value);
        if (choices.length !== selected.length) onValueChanged(name, choices);
        setSelected(choices);

        const ul = listRef.current;
        const children = ul?.children;

        for (let i = 0; i < (children?.length ?? 0); i++) {
            const li = children?.item(i) as HTMLLIElement;
            if (li.value === choice.value) li.classList.remove('selected');
        }
    }

    function selectChoice(choice: Choice) {
        const unique = [choice, ...selected].filter((c, i, all) => all.findIndex(cc => cc.value === c.value) === i);
        setSelected(unique);

        markSelected(choice);
        onValueChanged(name, unique);
    }

    function markSelected(choice: Choice) {
        const ul = listRef.current;
        const children = ul?.children;

        for (let i = 0; i < (children?.length ?? 0); i++) {
            const li = children?.item(i) as HTMLLIElement;
            if (li.value === choice.value) li.classList.add('selected');
        }
    }

    function choiceChanged(event: FormEvent<HTMLUListElement>) {
        const target = event.target as HTMLLIElement;
        if (!target) return;

        const choice = choices.find(c => c.value === target.value);
        if (choice && !selected.find(s => s.value === choice.value)) selectChoice(choice);
        setInputValue('');
        filterMultiselectItems('');
    }

    function inputChanged(event: FormEvent<HTMLInputElement>) {
        const currentTarget = event.currentTarget;
        const cursorPosition = currentTarget.selectionStart || 0;
        setCursorPosition(cursorPosition);
        const beforeCursor = currentTarget.value.substring(0, cursorPosition);
        const oldValue = inputValue;

        setInputValue(beforeCursor);

        // deselect previous autosuggestions
        choices.filter(c => !selected.find(s => s.value === c.value)).forEach(c => deselectChoice(c));

        // filter drop down items to match input
        const found = filterMultiselectItems(beforeCursor);
        setIsDropdownOpen(found);

        // user deleted part of input. No autocompletion
        if (currentTarget.value.length < oldValue.length) {
            return;
        }

        // autocompletion
        const choice = choices.find(c => c.name.toLowerCase().startsWith(beforeCursor.toLowerCase()))
        if (!choice) return;

        setInputValue(choice.name);
        markSelected(choice);
    }

    function inputKeyPressed(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key !== 'Enter') return;

        if (!inputRef.current) return;
        const value = inputRef.current.value;

        const choice = choices.find(c => c.name === value.trim());
        if (!choice) return;

        selectChoice(choice);
        setInputValue('');
        filterMultiselectItems('');
    }

    function inputLeaved(event: FormEvent<HTMLInputElement>) {
        setIsDropdownOpen(false);
        const choice = choices.find(c => c.name === event.currentTarget.value.trim());
        if (choice) selectChoice(choice);
        setInputValue('');
    }

    function toggleDropdown(_: FormEvent<HTMLDivElement>) {
        if (!isDropdownOpen) {
            filterMultiselectItems('');
        }

        setIsDropdownOpen(!isDropdownOpen);
    }

    const list = multiselectItems.map(c => {
        return (
            <li key={c.value} value={c.value}
                className={selected.find(s => s.value === c.value) ? 'selected' : ''}>{c.name}</li>
        )
    });

    const selections = selected.map(s => {
        return (
            <div key={s.value} className='multiselect-selection'>
                <p>{s.name}</p>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"
                     onClick={() => deselectChoice(s)}>
                    <path d="M0.72748 0.89752L8.68243 8.85247M0.72748 8.85247L8.68243 0.89752" stroke="#F9F7F7"
                          strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        )
    });

    const err = errors?.map(e => (<div key={'err-' + e} className='input-error dropdown-error'>{e}</div>));

    return (
        <div className={'input dropdown multiselect ' + (isDropdownOpen ? 'dropdown-open' : 'dropdown-closed')}>
            <label htmlFor={name}>{label}</label>
            <div className='input-with-icon'>
                <div className='multiselect-selections-wrapper'>
                    <div ref={selectionsRef} className='multiselect-selections'>
                        {selections}
                    </div>
                </div>
                <input name={name} type='text' ref={inputRef} value={inputValue} onChange={inputChanged}
                       onBlur={inputLeaved} onKeyUp={inputKeyPressed} autoComplete='off' spellCheck='false'
                       placeholder={placeholder}/>
                <div ref={inputIconRef} className='input-icon' onClick={toggleDropdown}
                     onMouseDown={(e) => e.preventDefault()}>
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.125 20.875C17.3028 20.875 21.5 16.6778 21.5 11.5C21.5 6.32219 17.3028 2.125 12.125 2.125C6.94719 2.125 2.75 6.32219 2.75 11.5C2.75 16.6778 6.94719 20.875 12.125 20.875Z"
                            stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path className='dropdown-arrow'
                              d="M8.8156 10.3187L12.125 13.6187L15.4343 10.3187"
                              stroke="#D9D9D9"
                              strokeWidth="1.40625"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
            {err}
            <div ref={dropdownRef} className="dropdown-choices-wrapper">
                <div className='dropdown-choices'>
                    <ul ref={listRef} onClick={choiceChanged} onMouseDown={(e) => e.preventDefault()}>
                        {list}
                    </ul>
                </div>
            </div>
        </div>
    );
};
