import React from "react";
import {ColumnDefinition, SortingOrder, TableProps} from "../../../stories/components/table/Table";
import {Rank} from "../../../shared/models/rank";
import {Role} from "../../../shared/models/role";

const columns: ColumnDefinition[] = [
    {
        title: 'Imie',
        name: 'firstName',
        columnStyle: {minWidth: '200px'},
        sort: (v1: string, v2: string, order: SortingOrder) => {
            return order * v1.localeCompare(v2);
        }
    },
    {
        title: 'Nazwisko',
        name: 'lastName',
        columnStyle: {minWidth: '200px'},
        sort: (v1: string, v2: string, order: SortingOrder) => {
            return order * v1.localeCompare(v2);
        }
    },
    {
        title: 'Płeć',
        name: 'gender',
        columnStyle: {minWidth: '50px'},
        sort: (v1: string, v2: string, order: SortingOrder) => {
            return order * v1.localeCompare(v2);
        }
    },
    {
        title: 'Data urodzenia',
        name: 'dateOfBirth',
        columnStyle: {minWidth: '200px'},
        template: (id: number, d: Date) => {
            return (
                <td key={d.toDateString()}>
                    {
                        ('0' + d.getDate()).slice(-2) + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + d.getFullYear()}
                </td>
            )
        },
        sort: (v1: Date, v2: Date, order: SortingOrder) => {
            return order * (v1.getTime() - v2.getTime());
        }
    },
    {
        title: 'Waga',
        name: 'weight',
        columnStyle: {minWidth: '50px'},
        sort: (v1: number, v2: number, order: SortingOrder) => {
            return order * (v1 - v2);
        }
    },
    {
        title: 'Stopień',
        name: 'rank',
        columnStyle: {minWidth: '200px'},
        template: (id: number, r: Rank) => {
            return (
                <td key={'rank-' + r.id}>
                    <div>{r.name}</div>
                </td>
            )
        }
    },
    {
        title: 'Role',
        name: 'roles',
        columnStyle: {width: '25%', minWidth: '300px'},
        template: (id: number, t: Role[]) => {
            const roles = t.map(r => (
                <div key={'user-role-' + r.id} className={'user-role'}>{r.name}</div>
            ));

            return (
                <td key={`roles-${id}`}>
                    <div className={'user-roles'}>
                        {roles}
                    </div>
                </td>
            )
        }
    },
];

export const tableProps: TableProps = {
    actionsColumnStyle: {width: '80px'},
    columns: columns,
    idColumn: 'id',
    data: [],
    noDataMessage: 'Brak uczestników w tej drużynie.',
    defaultSortColumn: 'lastName',
    defaultSortOrder: SortingOrder.Asc
}
