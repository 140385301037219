import React from 'react';
import './Button.scss';


interface ButtonProps {
    name: string;
    text: string;
    onButtonClicked: () => void;
    disabled?: boolean;
}

export const Button = ({name, text, onButtonClicked, disabled = false}: ButtonProps) => {
    return (
        <div className={'button'}>
            <button name={name} onClick={onButtonClicked} disabled={disabled}>{text}</button>
        </div>
    );
};
