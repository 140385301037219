import {FormState} from "./Form";

export function isNotNullOrEmpty(val: string, state: FormState) {
    return !!val && val.trim() !== '';
}

export function isRequired(val: any, state: FormState) {
    return !!val;
}

export function isNotEmptyCollection(val: any[], state: FormState) {
    return val?.length > 0;
}

export function isLongerThan(n: number) {
    return (val: string, state: FormState) => {
        return val?.length > n;
    }
}

export function isShorterThan(n: number) {
    return (val: string, state: FormState) => {
        return val?.length < n;
    }
}

export function isGreaterThan(n: number) {
    return (val: number, state: FormState) => {
        return val > n;
    }
}

export function isLessThan(n: number) {
    return (val: number, state: FormState) => {
        return val < n;
    }
}

