import React from "react";

import './Icons.scss';

export const iconDelete = (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.1875 6.35625C17.0656 6.04688 13.925 5.8875 10.7937 5.8875C8.9375 5.8875 7.08125 5.98125 5.225 6.16875L3.3125 6.35625M8.46875 5.40937L8.675 4.18125C8.825 3.29063 8.9375 2.625 10.5219 2.625H12.9781C14.5625 2.625 14.6844 3.32813 14.825 4.19063L15.0312 5.40937M18.1719 9.31875L17.5625 18.7594C17.4594 20.2312 17.375 21.375 14.7594 21.375H8.74062C6.125 21.375 6.04063 20.2312 5.9375 18.7594L5.32813 9.31875M10.1844 16.2188H13.3062M9.40625 12.4688H14.0938"
            stroke="#D9D9D9" strokeWidth="1.40625" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
)

export const iconEdit = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.1812 4.12502L5.48437 12.2719C5.19375 12.5813 4.9125 13.1906 4.85625 13.6125L4.50937 16.65C4.3875 17.7469 5.175 18.4969 6.2625 18.3094L9.28125 17.7938C9.70312 17.7188 10.2937 17.4094 10.5844 17.0906L18.2812 8.94377C19.6125 7.53752 20.2125 5.9344 18.1406 3.97502C16.0781 2.0344 14.5125 2.71877 13.1812 4.12502Z"
            stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
            strokeLinejoin="round"/>
        <path
            d="M11.8969 5.48438C12.0934 6.74143 12.7018 7.89763 13.6266 8.77148C14.5513 9.64534 15.7401 10.1874 17.0063 10.3125M3.5625 21.375H20.4375"
            stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
)

export const iconEditUser = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.0625 2.625H9.1875C4.5 2.625 2.625 4.5 2.625 9.1875V14.8125C2.625 19.5 4.5 21.375 9.1875 21.375H14.8125C19.5 21.375 21.375 19.5 21.375 14.8125V12.9375"
            stroke="#D9D9D9" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M15.7875 3.58124L8.39999 10.9687C8.11874 11.25 7.83749 11.8031 7.78124 12.2062L7.37812 15.0281C7.22812 16.05 7.94999 16.7625 8.97187 16.6219L11.7937 16.2187C12.1875 16.1625 12.7406 15.8812 13.0312 15.6L20.4187 8.21249C21.6937 6.93749 22.2937 5.45624 20.4187 3.58124C18.5437 1.70624 17.0625 2.30624 15.7875 3.58124Z"
            stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
            strokeLinejoin="round"/>
        <path
            d="M14.7281 4.64062C15.039 5.7445 15.6281 6.75006 16.439 7.56098C17.2499 8.3719 18.2555 8.96101 19.3594 9.27187"
            stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
)

export const iconArrowUpDown = (isDownOriented: boolean, isSelected: boolean) => {
    return (
        <svg className={'icon-arrow-up-down ' + (isSelected ? 'selected' : '')} width="24" height="23"
             viewBox="0 0 24 23" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.125 20.875C17.3028 20.875 21.5 16.6778 21.5 11.5C21.5 6.32219 17.3028 2.125 12.125 2.125C6.94719 2.125 2.75 6.32219 2.75 11.5C2.75 16.6778 6.94719 20.875 12.125 20.875Z"
                stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path className={isDownOriented ? 'arrow-down' : 'arrow-up'}
                  d="M8.8156 10.3187L12.125 13.6187L15.4343 10.3187"
                  stroke="#D9D9D9"
                  strokeWidth="1.40625"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
