import React, {useEffect} from 'react';
import './Toast.scss';

export interface IToast {
    id: string
    title: string;
    message: string;
    status: ToastStatus;
}

export enum ToastStatus {
    Succeed,
    Failed
}

interface ToastProps {
    close: () => void;
    toast: IToast;
}

export const Toast = ({toast, close}: ToastProps) => {

    useEffect(() => {
        setTimeout(() => {
            close();
        }, 6000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={'toast'}>
            <div className={'toast-icon'}>

                {
                    toast.status === ToastStatus.Succeed ?
                        <svg className={'succeed'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 21.375C17.1778 21.375 21.375 17.1778 21.375 12C21.375 6.82219 17.1778 2.625 12 2.625C6.82219 2.625 2.625 6.82219 2.625 12C2.625 17.1778 6.82219 21.375 12 21.375Z"
                                stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10"/>
                            <path d="M9 12.2688L11.2594 14.5375L15.7875 10" stroke="#D9D9D9" strokeWidth="1.40625"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        :
                        <svg className={'failed'} viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.34688 14.6531C11.4191 12.5809 12.5809 11.4191 14.6531 9.34688M14.6531 14.6531L9.34688 9.34688M12 21.375C17.1562 21.375 21.375 17.1562 21.375 12C21.375 6.84375 17.1562 2.625 12 2.625C6.84375 2.625 2.625 6.84375 2.625 12C2.625 17.1562 6.84375 21.375 12 21.375Z"
                                stroke="#D9D9D9" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                }
            </div>
            <div className={'toast-body'}>
                <div className={'toast-title'}>{toast.title}</div>
                <div className={'toast-message'}>{toast.message}</div>
            </div>
            <div className={'toast-close'} onClick={() => close()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.34688 14.6531C11.4191 12.5809 12.5809 11.4191 14.6531 9.34688M14.6531 14.6531L9.34688 9.34688M12 21.375C17.1562 21.375 21.375 17.1562 21.375 12C21.375 6.84375 17.1562 2.625 12 2.625C6.84375 2.625 2.625 6.84375 2.625 12C2.625 17.1562 6.84375 21.375 12 21.375Z"
                        stroke="#D9D9D9" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    );
};
