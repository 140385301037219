import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import {apiUrl} from "../../environment";
import {App} from "../../shared/models/app";

export const fetchAppAsync = createAsyncThunk('app/get', async () => {
    const response = await axios.get<App>(`${apiUrl}/app`);
    return response.data;
})

export interface AppState {
    app: App | null;
    status: string;
}

const initialState: AppState = {
    app: null,
    status: 'idle'
};

export const appSlice = createSlice({
        name: 'app',
        initialState,
        reducers: {},
        extraReducers(builder) {
            builder
                .addCase(fetchAppAsync.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(fetchAppAsync.fulfilled, (state, action) => {
                    state.app = action.payload;
                    state.status = 'succeeded';
                })
                .addCase(fetchAppAsync.rejected, (state, action) => {
                    state.status = 'failed';
                })
        }
    }
);
