import {RadioChoice} from "../../stories/components/input-radio/InputRadio";

export const genders: RadioChoice[] = [
    {
        value: 2,
        name: 'Kobieta',
    },
    {
        value: 1,
        name: 'Mężczyzna',
        selected: true
    },
]
