import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import {apiUrl} from "../../environment";
import {Contestant, ContestantCreateModel} from "../../shared/models/contestant";

export const fetchContestantsAction = createAsyncThunk('contestant/get-list', async (payload: { dojoId: number }) => {
    const response = await axios.get<Contestant[]>(`${apiUrl}/Contestant/${payload.dojoId}`);
    return response.data;
})

export const createContestantAction = createAsyncThunk('contestant/create', async (payload: { dojoId: number, contestant: ContestantCreateModel }) => {
    await axios.post(`${apiUrl}/Contestant/${payload.dojoId}`, payload.contestant);
})

export const updateContestantAction = createAsyncThunk('contestant/update', async (payload: { id: number, contestant: ContestantCreateModel }) => {
    await axios.put(`${apiUrl}/Contestant/${payload.id}`, payload.contestant);
})

export const deleteContestantAction = createAsyncThunk('contestant/delete', async (payload: { id: number }) => {
    await axios.delete(`${apiUrl}/Contestant/${payload.id}`);
})

export interface ContestantState {
    contestants: Contestant[];
    status: string;
}

const initialState: ContestantState = {
    contestants: [],
    status: 'idle'
};

export const contestantSlice = createSlice({
        name: 'contestants',
        initialState,
        reducers: {
            resetContestantsState: (state, action: PayloadAction) => {
                state.contestants = initialState.contestants;
                state.status = initialState.status;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(fetchContestantsAction.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(fetchContestantsAction.fulfilled, (state, action) => {
                    state.contestants = action.payload;
                    state.status = 'succeeded';
                })
                .addCase(fetchContestantsAction.rejected, (state, action) => {
                    state.status = 'failed';
                })
                .addCase(createContestantAction.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(createContestantAction.rejected, (state, action) => {
                    state.status = 'failed';
                })
                .addCase(updateContestantAction.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(updateContestantAction.rejected, (state, action) => {
                    state.status = 'failed';
                })
                .addCase(deleteContestantAction.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(deleteContestantAction.rejected, (state, action) => {
                    state.status = 'failed';
                })
                .addCase(createContestantAction.fulfilled, (state, action) => {
                    state.status = 'idle';
                })
                .addCase(updateContestantAction.fulfilled, (state, action) => {
                    state.status = 'idle';
                })
                .addCase(deleteContestantAction.fulfilled, (state, action) => {
                    state.status = 'idle';
                })
        }
    }
);

export const {resetContestantsState} = contestantSlice.actions;
