export const firstCharToUpper = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const getClientX = (event: MouseEvent | TouchEvent) => {
    if (event instanceof MouseEvent) {
        return event.clientX;
    } else {
        return event.touches[0].clientX;
    }
}

export const preventDefault = (event: MouseEvent | TouchEvent) => {
    if (event instanceof MouseEvent) {
        event.preventDefault();
    }
}
