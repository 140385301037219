import React, {useCallback, useState} from "react";
import {FormState, FormValidators} from "./Form";

function useForm(defaultState: FormState, formValidators: FormValidators, defaultValid: boolean = false) {
    const [formState, setFormState] = useState<FormState>(defaultState);
    const [isFormValid, setIsFormValid] = useState(defaultValid);
    const [formTouched, setFormTouched] = useState(false);
    const [resetFunctions, setResetFunctions] = useState<Map<string, () => void>>(new Map<string, () => void>());
    const [populateFunctions, setPopulateFunctions] = useState<Map<string, (value: any, errors: string[]) => void>>(new Map<string, (value: any) => void>());

    // initial population
    React.useEffect(() => {
        for (const key in defaultState) {
            populate(key, defaultState[key]?.value, defaultState[key]?.errors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // form validity
    React.useEffect(() => {

        if (!formTouched) return;

        for (const k in formState) {
            const inputState = formState[k];
            if (!inputState?.errors || inputState.errors.length || validateInput(k, inputState.value).length) {
                setIsFormValid(false);
                return;
            }
        }

        setIsFormValid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    const registerInputResetFunction = useCallback((name: string, f: () => void) => {
        const reset = () => {
            setFormState(formState => {
                return {...formState, [name]: defaultState[name]}
            });

            f();
        }

        setResetFunctions(new Map(resetFunctions.set(name, reset)))
    }, [defaultState, resetFunctions]);

    const registerInputPopulateFunction = useCallback((name: string, f: (value: any) => void) => {
        const populate = (value: any, errors: string[]) => {
            formState[name] = {...formState[name], value, errors};
            // setFormState(formState => {
            //     return {...formState, [name]: {...formState[name], value, errors}}
            // })

            f(value);
        }

        setPopulateFunctions(new Map(populateFunctions.set(name, populate)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [populateFunctions]);

    const validateInput = useCallback((name: string, value: any) => {
        const validators = formValidators[name as keyof typeof formValidators];
        return validators?.map(v => v.validator(value, formState) ? '' : v.error).filter(e => e !== '') ?? [];
    }, [formState, formValidators]);

    // allows to register your own input
    const onValueChanged = useCallback((name: string, value: any) => {
        // validate
        const errors = validateInput(name, value);
        setFormTouched(true);
        setFormState((formState) => {
            return {...formState, [name]: {...formState[name], value, errors}}
        })
    }, [validateInput]);

    // reset (single input) = reset state and form value
    const reset = useCallback((name: string) => {
        const f = resetFunctions.get(name);
        if (f) f();
    }, [resetFunctions]);
    // validate (single input) = set input state errors
    const validate = useCallback((name: string) => {
        const errors = validateInput(name, formState[name]?.value);
        setFormState(formState => {
            return {...formState, [name]: {...formState[name], errors, value: formState[name]?.value}};
        });
    }, [formState, validateInput]);
    // populate single input state and form value
    const populate = useCallback((name: string, value: any, errors: string[] = []) => {
        // populate
        const populate = populateFunctions.get(name);
        if (populate) populate(value, errors);
    }, [populateFunctions]);
    // update (single input) = validate + set state + set form value
    const update = useCallback((name: string, value: any) => {
        // validate
        const errors = validateInput(name, value);

        populate(value, errors);
    }, [populate, validateInput]);
    // disable (single input)
    const disable = useCallback((name: string, disabled: boolean = true) => {
        setFormState(formState => {
            const current = formState[name];
            if (current) {
                return {...formState, [name]: {...current, disabled}};
            } else {
                return {...formState, [name]: {value: null, errors: [], disabled}};
            }
        })
    }, []);

    const register = (name: string) => {
        return {
            name: name,
            disabled: formState[name]?.disabled,
            errors: formState[name]?.errors,
            onValueChanged: onValueChanged,
            resetFunctionRegistar: registerInputResetFunction,
            populateFunctionRegistar: registerInputPopulateFunction
        }
    };

    return {register, reset, validate, populate, update, disable, formState, isFormValid, onValueChanged, formTouched};
}

export default useForm;
