import React, {ReactNode} from 'react';
import './Title.scss';


interface TitleProps {
    children: ReactNode;
}

export const Title = ({children}: TitleProps) => {
    return (
       <h1>{children}</h1>
    );
};
