import React from 'react';
import {Outlet} from "react-router-dom";
import {useKarateSelector} from "./store/Hooks";

import './App.scss';
import {Nav} from "./stories/components/nav/Nav";

export const App = () => {

    const dojo = useKarateSelector(state => state.dojos.dojo);

    return (
        <div className='app'>
            <Nav title={dojo?.name ?? ''}/>
            <div className='content'>
                <Outlet/>
            </div>
        </div>
    );
};
