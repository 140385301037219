import React, {useEffect, useState} from "react";

import {Table} from "../../../stories/components/table/Table";
import {tableProps} from "./ContestantListProps";
import {useKarateDispatch, useKarateSelector} from "../../../store/Hooks";
import {useToast} from "../../../stories/contexts/toast/UseToast";
import {deleteContestantAction, fetchContestantsAction} from "../../../store/slices/ContestantSlice";
import {ToastStatus} from "../../../stories/components/toast/Toast";
import {Loader} from "../../../stories/components/loader/Loader";
import {Title} from "../../../stories/components/title/Title";
import {Contestant} from "../../../shared/models/contestant";

import '../../shared/LayoutList.scss';
import "./ContestantList.scss";

interface ContestantListProps {
    editContestantCallback: (contestant: Contestant | null) => void,
}

export const ContestantList = ({editContestantCallback}: ContestantListProps) => {

    const dispatch = useKarateDispatch();

    const dojo = useKarateSelector(state => state.dojos.dojo);
    const status = useKarateSelector(state => state.contestants.status);
    const contestants = useKarateSelector(state => state.contestants.contestants).map<Contestant>(c => {
        return {...c, dateOfBirth: new Date(c.dateOfBirth)}
    });

    const toast = useToast();

    const [expired, setExpired] = useState(false);

    useEffect(() => {
        if (status === 'idle' && dojo) {

            dispatch(fetchContestantsAction({dojoId: dojo.id}));

            setExpired(false);
            setTimeout(() => {
                setExpired(true);
            }, 1000)
        }
    }, [dispatch, dojo, status]);

    function updateContestant(id: number) {
        editContestantCallback(contestants.find(c => c.id === id) ?? null);
    }

    async function deleteContestant(id: number) {
        try {
            await dispatch(deleteContestantAction({id})).unwrap();
            toast.open('Succeed', 'Contestant deleted');
        } catch (e: any) {
            toast.open('Failed', e.message, ToastStatus.Failed);
        }
    }

    let content = <div>
        <div className='list-title'>
            <h1>Uczestnicy drużyny</h1>
        </div>

        <Table {...tableProps} data={contestants}
               onRowDelete={deleteContestant} onRowEdit={updateContestant}/>
    </div>

    if (status === 'loading' || !expired) {
        content = (
            <div className='loader-wrapper'>
                <Loader/>
            </div>
        )
    }

    if (status === 'failed') {
        content =
            (
                <div className='error-page'>
                    <Title>Oops</Title>
                    <div className='error-description'>
                        <p className='error-sorry'>
                            Sorry, an unexpected error has occurred.
                        </p>
                        <p className='error-reason'>
                            <i>
                                Server error
                            </i>
                        </p>
                    </div>
                </div>
            )
    }

    return (
        <div className='item-list'>
            {content}
        </div>
    )
}
