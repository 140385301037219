import React from 'react';
import {useRouteError} from 'react-router-dom';

import {Title} from "../../../stories/components/title/Title";

import './Error.scss';

export function Error() {
    const error: any = useRouteError();

    return (
        <div className='error-page'>
            <Title>Oops</Title>
            <div className='error-description'>
                <p className='error-sorry'>
                    Sorry, an unexpected error has occurred.
                </p>
                <p className='error-reason'>
                    <i>
                        {error.statusText || error.message}
                    </i>
                </p>
            </div>
        </div>
    )
}
