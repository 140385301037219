import React, {useState} from 'react';
import {Choice, FormInput} from "../../shared/form/Form";

import './InputRadio.scss';

export interface RadioChoice {
    name: string;
    value: string | number;
    selected?: boolean
}

interface RadioProps extends FormInput<Choice> {
    name: string;
    label: string;
    choices: RadioChoice[];
}

export const InputRadio = ({
                               name,
                               label,
                               choices,
                               onValueChanged,
                               resetFunctionRegistar,
                               populateFunctionRegistar
                           }: RadioProps) => {

    const [selected, setSelected] = useState(choices.find(c => c.selected));

    React.useEffect(() => {
        if (resetFunctionRegistar) resetFunctionRegistar(name, reset);
        if (populateFunctionRegistar) populateFunctionRegistar(name, populate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function populate(value: RadioChoice) {
        setSelected(value);
    }

    function reset() {
        setSelected(choices.find(c => c.selected));
    }

    function onChoiceChanged(name: string, choice: Choice) {
        setSelected(choice);
        onValueChanged(name, choice);
    }

    const inputs = choices.map(c => {
        return (
            <span key={c.name}>
                <input name={name} id={name + '-' + c.name ?? c.value} value={c.value}
                       type='radio' autoComplete='off'
                       checked={c.value === selected?.value} onChange={() => onChoiceChanged(name, c)}/>
                <label htmlFor={name + '-' + c.name ?? c.value}>{c.name}</label>
            </span>
        )
    });

    return (
        <div className='input-radio'>
            <label className='input-label' htmlFor={name}>{label}</label>
            <span>
               {inputs}
            </span>
        </div>
    );
};
