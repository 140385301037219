import React, {useCallback, useEffect, useState} from 'react';

import {useKarateDispatch} from "../../../store/Hooks";
import {Loader} from "../../../stories/components/loader/Loader";
import {Title} from "../../../stories/components/title/Title";

import {Contestant} from "../../../shared/models/contestant";
import {useToast} from "../../../stories/contexts/toast/UseToast";
import {fetchDojoAction} from "../../../store/slices/DojoSlice";
import {fetchRolesAction} from "../../../store/slices/RoleSlice";
import {fetchRanksAction} from "../../../store/slices/RankSlice";
import {ToastStatus} from "../../../stories/components/toast/Toast";
import {ContestantForm} from "../contestant-form/ContestantForm";
import {ContestantList} from "../contestant-list/ContestantList";
import {useParams} from "react-router-dom";

import '../../shared/Layout.scss';
import './Home.scss';

export function Home() {
    const dispatch = useKarateDispatch();

    const {dojoLink} = useParams();
    const toast = useToast();

    const [editContestant, setEditContestant] = useState<Contestant | null>(null);
    const [formDate, setFormDate] = useState(new Date());
    const [expired, setExpired] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [status, setStatus] = useState('idle');

    useEffect(() => {
        Promise.all([
            dispatch(fetchDojoAction({link: dojoLink!})).unwrap(),
            dispatch(fetchRolesAction()).unwrap(),
            dispatch(fetchRanksAction()).unwrap(),
        ])
            .catch((e) => {
                setStatus('failed');
                toast.open('Błąd', e.message, ToastStatus.Failed);
            })
            .finally(() => setLoaded(true));

        setExpired(false);
        setTimeout(() => {
            setExpired(true);
        }, 1000)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetForm = useCallback(() => {
        setEditContestant(null);
        setFormDate(new Date());
    }, []);

    const editContestantCallback = useCallback((contestant: Contestant | null) => {
        setEditContestant(contestant);
    }, []);

    let content =
        (
            <div className='crud-layout'>
                <div className="crud-form-wrapper">
                    <ContestantForm key={editContestant?.id + '-' + formDate} editContestant={editContestant}
                                    resetForm={resetForm}/>
                </div>
                <div className='crud-list-wrapper'>
                    <ContestantList editContestantCallback={editContestantCallback}/>
                </div>
            </div>
        )

    if (!loaded || !expired) {
        content =
            (
                <div className='loader-wrapper'>
                    <Loader/>
                </div>
            )
    }

    if (status === 'failed') {
        content =
            (
                <div className='error-page'>
                    <Title>Oops</Title>
                    <div className='error-description'>
                        <p className='error-sorry'>
                            Sorry, an unexpected error has occurred.
                        </p>
                        <p className='error-reason'>
                            <i>
                                Server error
                            </i>
                        </p>
                    </div>
                </div>
            )
    }

    return (
        <div className='home'>
            {content}
        </div>
    )
}
