import {configureStore} from '@reduxjs/toolkit';
import {dojoSlice} from "./slices/DojoSlice";
import {contestantSlice} from "./slices/ContestantSlice";
import {ranksSlice} from "./slices/RankSlice";
import {roleSlice} from "./slices/RoleSlice";
import {appSlice} from "./slices/AppSlice";

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        dojos: dojoSlice.reducer,
        contestants: contestantSlice.reducer,
        ranks: ranksSlice.reducer,
        roles: roleSlice.reducer
    },
});


export type KarateDispatch = typeof store.dispatch;
export type KarateState = ReturnType<typeof store.getState>;
