import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import {apiUrl} from "../../environment";
import {Rank} from "../../shared/models/rank";

export const fetchRanksAction = createAsyncThunk('rank/get-list', async () => {
    const response = await axios.get<Rank[]>(`${apiUrl}/rank`);
    return response.data;
})

export interface RankState {
    ranks: Rank[];
    status: string;
}

const initialState: RankState = {
    ranks: [],
    status: 'idle'
};

export const ranksSlice = createSlice({
        name: 'ranks',
        initialState,
        reducers: {
            resetRankState: (state, action: PayloadAction) => {
                state.ranks = initialState.ranks;
                state.status = initialState.status;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(fetchRanksAction.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(fetchRanksAction.fulfilled, (state, action) => {
                    state.ranks = action.payload;
                    state.status = 'succeeded';
                })
                .addCase(fetchRanksAction.rejected, (state, action) => {
                    state.status = 'failed';
                })
        }
    }
);

export const {resetRankState} = ranksSlice.actions;
