import React, {FormEvent, useRef, useState} from 'react';
import './InputDate.scss';
import {FormInput} from "../../shared/form/Form";
import {firstCharToUpper} from "../../shared/Helpers";

interface InputDateProps extends FormInput<Date | null> {
    label: string;
    name: string;
}

enum CalendarMode {
    Date,
    Month,
    Year
}

interface DateChosenParams {
    year: number,
    month: number,
    date: number,
    changeMode?: boolean,
    closeCalendar?: boolean
}

export const InputDate = ({
                              name,
                              label,
                              onValueChanged,
                              resetFunctionRegistar,
                              populateFunctionRegistar,
                              errors
                          }: InputDateProps) => {
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [currentMode, setCurrentMode] = useState<CalendarMode>(CalendarMode.Date);
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const inputIconRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);

    const locales = 'pl-PL';
    let oldValue = '';

    React.useEffect(() => {
        function handleClickOutside(event: any) {
            const target = event.target as Node;

            if (!(event.target.offsetWidth || event.target.offsetHeight || event.target.getClientRects().length)) {
                return;
            }

            if (!inputIconRef.current?.contains(target) && !calendarRef.current?.contains(target)) {
                setIsCalendarOpen(false);
            }
        }

        if (isCalendarOpen) {
            document.addEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, [calendarRef, isCalendarOpen]);

    React.useEffect(() => {
        if (resetFunctionRegistar) resetFunctionRegistar(name, reset);
        if (populateFunctionRegistar) populateFunctionRegistar(name, populate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function populate(value: Date | null) {
        if (value) {
            setCurrentDate(value);
            inputRef.current!.value = ('0' + value.getDate()).slice(-2) + '-' + ('0' + (1 + value.getMonth())).slice(-2) + '-' + value.getFullYear();
        }
    }

    function reset() {
        setCurrentDate(new Date());
        setCurrentMode(CalendarMode.Date);
        setIsCalendarOpen(false);

        inputRef.current!.value = '';
    }

    function transform(event: FormEvent<HTMLInputElement>) {
        let currentValue = event.currentTarget.value;
        let cursorPosition = event.currentTarget.selectionStart ?? 0;

        // user removed character
        if (currentValue.slice(0, cursorPosition) + '-' + currentValue.slice(cursorPosition) === oldValue) {
            currentValue = currentValue.slice(0, cursorPosition - 1) + currentValue.slice(cursorPosition);
            cursorPosition--;
        }

        currentValue = currentValue.replace(/[^0-9]/g, '') || '';

        if (currentValue.length > 1) currentValue = currentValue.slice(0, 2) + '-' + currentValue.slice(2);
        if (currentValue.length > 4) currentValue = currentValue.slice(0, 5) + '-' + currentValue.slice(5);
        if (currentValue.length > 10) currentValue = currentValue.slice(0, 10);

        if (cursorPosition === 2 || cursorPosition === 5 || cursorPosition + 1 === currentValue.length) cursorPosition += 1;

        oldValue = currentValue;

        event.currentTarget.value = currentValue;
        event.currentTarget.selectionStart = cursorPosition;
        event.currentTarget.selectionEnd = cursorPosition;
    }

    function inputBlurred(event: FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;

        const parts = value.split('-');

        if (parts.length !== 3 || parts[2].length !== 4) {
            setCurrentDate(new Date());
            event.currentTarget.value = '';
            onValueChanged(name, null);
            return;
        }

        const date = Number(parts[0]);
        const month = Number(parts[1]) - 1;
        const year = Number(parts[2]);

        if (isNaN(date) || isNaN(month) || isNaN(year)) {
            setCurrentDate(new Date());
            event.currentTarget.value = '';
            onValueChanged(name, null);
            return;
        }

        const newDate = new Date(year, month, date);

        populate(newDate);
        setIsCalendarOpen(false);
        onValueChanged(name, newDate);
    }

    function toggleDropdown(event: FormEvent<HTMLDivElement>) {
        if (!isCalendarOpen) {
            dateChosen({year: currentDate.getFullYear(), month: currentDate.getMonth(), date: currentDate.getDate()});
        }

        setIsCalendarOpen(!isCalendarOpen);
    }

    function previousButtonClicked() {
        const newDate = new Date(currentDate);

        if (currentMode === CalendarMode.Date) {
            newDate.setMonth(newDate.getMonth() - 1);
            setCurrentDate(newDate);
        }

        if (currentMode === CalendarMode.Year) {
            newDate.setFullYear(newDate.getFullYear() - 30);
            setCurrentDate(newDate);
        }

        dateChosen({
            year: newDate.getFullYear(),
            month: newDate.getMonth(),
            date: newDate.getDate(),
            changeMode: false,
            closeCalendar: false
        });
    }

    function nextButtonClicked() {
        const newDate = new Date(currentDate);

        if (currentMode === CalendarMode.Date) {
            newDate.setMonth(newDate.getMonth() + 1);
            setCurrentDate(newDate);
        }

        if (currentMode === CalendarMode.Year) {
            newDate.setFullYear(newDate.getFullYear() + 30);
            setCurrentDate(newDate);
        }

        dateChosen({
            year: newDate.getFullYear(),
            month: newDate.getMonth(),
            date: newDate.getDate(),
            changeMode: false
        });
    }

    function dateChosen({year, month, date, changeMode = true, closeCalendar = false}: DateChosenParams) {
        const newDate = new Date(currentDate);
        newDate.setFullYear(year);
        newDate.setMonth(month);
        newDate.setDate(date);
        setCurrentDate(newDate);
        if (changeMode) setCurrentMode(CalendarMode.Date);

        const dateParts = newDate.toLocaleString(locales, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }).split('.');
        inputRef.current!.value = ('0' + dateParts[0]).slice(-2) + '-' + ('0' + dateParts[1]).slice(-2) + '-' + dateParts[2];

        if (closeCalendar) setIsCalendarOpen(false);

        onValueChanged(name, newDate);
    }

    const monthName = () => {
        const monthName = currentDate.toLocaleString(locales, {month: "long"});
        return firstCharToUpper(monthName);
    }

    const calendarDays = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const date = currentDate.getDate();

        const firstMonthDay = new Date(year, month, 1);
        const lastMonthDay = new Date(year, month + 1, 0);
        const dayOfWeekBasis = new Date(2023, 4, 1);


        const dayNames: JSX.Element[] = [];
        const days: JSX.Element[] = [];

        // days of week from Monday
        for (let i = 1; i <= 7; i++) {
            dayOfWeekBasis.setDate(i);

            let dayName = dayOfWeekBasis.toLocaleString(locales, {weekday: "short"});
            dayName = dayName.slice(0, dayName.length - 1);
            dayName = firstCharToUpper(dayName);

            dayNames.push(<div key={'day-name-' + i}
                               className='calendar-grid-item calendar-day-name no-select'>{dayName}</div>);
        }

        // previous month days
        for (let i = (firstMonthDay.getDay() || 7) - 1; i > 0; i--) {
            const dayNumber = new Date(year, month, 1 - i).getDate();

            days.push(
                <div key={'other-month-day-' + dayNumber} className='calendar-grid-item'
                     onClick={() => dateChosen({year, month: month - 1, date: dayNumber})}>
                    <div className='calendar-day other-month no-select'>{dayNumber}</div>
                </div>
            )
        }

        // actual days
        for (let dayNumber = 1; dayNumber <= lastMonthDay.getDate(); dayNumber++) {
            days.push(
                <div key={'day-' + dayNumber} className='calendar-grid-item'
                     onClick={() => dateChosen({year, month, date: dayNumber})}>
                    <div className={'calendar-day ' + (dayNumber === date ? 'day-selected' : '')}>{dayNumber}</div>
                </div>)
        }

        // next month days
        for (let dayNumber = 1; days.length < 42; dayNumber++) {
            days.push(
                <div key={'other-month-day-' + dayNumber} className='calendar-grid-item'
                     onClick={() => dateChosen({year, month: month + 1, date: dayNumber})}>
                    <div className='calendar-day other-month no-select'>{dayNumber}</div>
                </div>
            )
        }

        return (
            <div className='calendar-day-names'>
                {dayNames}
                {days}
            </div>
        );
    }

    const calendarMonths = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const date = currentDate.getDate();

        const months: JSX.Element[] = [];


        for (let i = 0; i < 12; i++) {
            let monthName = new Date(year, i, 1).toLocaleString(locales, {month: "long"});
            monthName = firstCharToUpper(monthName);

            months.push(
                <div key={'month-name-' + monthName.toLowerCase()} className='calendar-grid-item'>
                    <div className={'calendar-month ' + (i === month ? 'month-selected' : '')}
                         onClick={() => dateChosen({year, month: i, date})}>
                        {monthName}
                    </div>
                </div>
            );
        }

        return (
            <div className='calendar-months'>
                {months}
            </div>
        );
    }

    const calendarYears = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const date = currentDate.getDate();
        const years: JSX.Element[] = [];

        for (let i = 12; i >= 1; i--) {
            const y = year - i;
            years.push(
                <div key={'year-' + y} className='calendar-grid-item'>
                    <div className='calendar-year'
                         onClick={() => dateChosen({year: y, month: month, date: date})}>{y}</div>
                </div>
            );
        }

        years.push(
            <div key={'year-' + year} className='calendar-grid-item'>
                <div className='calendar-year year-selected'
                     onClick={() => dateChosen({year, month, date})}>{year}</div>
            </div>
        );

        for (let i = 1; i < 18; i++) {
            const y = year + i;
            years.push(
                <div key={'year' + y} className='calendar-grid-item'>
                    <div className='calendar-year'
                         onClick={() => dateChosen({year: y, month, date})}>{y}</div>
                </div>
            );
        }

        return (
            <div className='calendar-years'>
                {years}
            </div>
        );
    }

    const calendarBody = () => {
        switch (currentMode) {
            case CalendarMode.Date:
                return calendarDays();
            case CalendarMode.Month:
                return calendarMonths();
            case CalendarMode.Year:
                return calendarYears();
        }
    }

    const err = errors?.map(e => (<div key={'err-' + e} className='input-error'>{e}</div>));

    return (
        <div className={'input input-date ' + (isCalendarOpen ? 'calendar-open' : 'calendar-closed')}>
            <label htmlFor={name}>{label}</label>
            <div className='input-with-icon'>
                <input ref={inputRef} name={name} placeholder={'dd-mm-yyyy'} type='text' autoComplete='off'
                       onInput={transform} onBlur={inputBlurred}/>
                <div ref={inputIconRef} className='input-icon' onClick={toggleDropdown}>
                    <svg width="24" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.375 2.25V5.0625M15.875 2.25V5.0625M4.15625 8.89688H20.0938M20.5625 8.34375V16.3125C20.5625 19.125 19.1562 21 15.875 21H8.375C5.09375 21 3.6875 19.125 3.6875 16.3125V8.34375C3.6875 5.53125 5.09375 3.65625 8.375 3.65625H15.875C19.1562 3.65625 20.5625 5.53125 20.5625 8.34375Z"
                            stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M15.589 13.2188H15.5974M15.589 16.0313H15.5974M12.1203 13.2188H12.1296M12.1203 16.0313H12.1296M8.65057 13.2188H8.65995M8.65057 16.0313H8.65995"
                            stroke="#D9D9D9" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
            {err}
            <div ref={calendarRef} className='calendar-wrapper'>
                <div className="calendar-year-month-wrapper">
                    <div className='calendar-icon' onClick={previousButtonClicked}>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.5 20.875C16.6778 20.875 20.875 16.6778 20.875 11.5C20.875 6.32219 16.6778 2.125 11.5 2.125C6.32219 2.125 2.125 6.32219 2.125 11.5C2.125 16.6778 6.32219 20.875 11.5 20.875Z"
                                stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M12.6813 14.8094L9.38135 11.5L12.6813 8.19063" stroke="#D9D9D9"
                                  strokeWidth="1.40625"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>

                    <div className='calendar-year-month'>
                        <span onClick={() => setCurrentMode(CalendarMode.Month)}>{monthName()}</span>
                        <span onClick={() => setCurrentMode(CalendarMode.Year)}>{currentDate.getFullYear()}</span>
                    </div>

                    <div className='calendar-icon' onClick={nextButtonClicked}>
                        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.9998 20.875C17.1776 20.875 21.3748 16.6778 21.3748 11.5C21.3748 6.32219 17.1776 2.125 11.9998 2.125C6.82194 2.125 2.62476 6.32219 2.62476 11.5C2.62476 16.6778 6.82194 20.875 11.9998 20.875Z"
                                stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M10.8186 14.8094L14.1186 11.5L10.8186 8.19062" stroke="#D9D9D9"
                                  strokeWidth="1.40625"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>

                </div>
                <div className='calendar-body'>
                    {calendarBody()}
                </div>
            </div>
        </div>
    );
};
