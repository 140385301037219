import {createBrowserRouter} from "react-router-dom";
import {appPrefix} from "./environment";
import {App} from "./App";
import {store} from "./store/Store";

import {Error} from "./shared/components/error/Error";
import {Home} from "./home/components/home/Home";
import {resetRoleState} from "./store/slices/RoleSlice";
import {resetContestantsState} from "./store/slices/ContestantSlice";
import {resetDojoState} from "./store/slices/DojoSlice";
import {resetRankState} from "./store/slices/RankSlice";
import {fetchAppAsync} from "./store/slices/AppSlice";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <Error/>,
        loader: async () => {
            await store.dispatch(fetchAppAsync());
            if (!store.getState().app.app?.isRegistrationInProcess) {
                throw new Response("", {
                    status: 404,
                    statusText: 'Obecnie nie jest przeprowadzana zadna rejestracja. Spobuj ponownie pozniej.'
                });
            }
            return null;
        },
        children: [
            {
                path: '/:dojoLink',
                element: <Home/>,
                loader: () => {
                    store.dispatch(resetDojoState());
                    store.dispatch(resetContestantsState());
                    store.dispatch(resetRoleState());
                    store.dispatch(resetRankState());
                    return null;
                }
            },
            {
                index: true,
                loader: () => {
                    throw new Response("Not Found", {
                        status: 404,
                        statusText: 'Nie zostal dostarczony link do zespolu'
                    });
                }
            }
        ]
    },
], {basename: appPrefix});
