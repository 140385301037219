import React, {useState} from "react";
import {Input} from "../../../stories/components/input/Input";
import {InputRadio} from "../../../stories/components/input-radio/InputRadio";
import {InputDate} from "../../../stories/components/input-date/InputDate";
import {InputRange} from "../../../stories/components/input-range/InputRange";
import {InputDropdown} from "../../../stories/components/input-dropdown/InputDropdown";
import {Button} from "../../../stories/components/button/Button";
import {
    isGreaterThan,
    isLongerThan,
    isNotEmptyCollection,
    isNotNullOrEmpty,
    isRequired,
    isShorterThan
} from "../../../stories/shared/form/Validators";
import {InputMultiselect} from "../../../stories/components/input-multiselect/InputMultiselect";
import {Contestant, ContestantCreateModel} from "../../../shared/models/contestant";
import useForm from "../../../stories/shared/form/UseForm";
import {useKarateDispatch, useKarateSelector} from "../../../store/Hooks";

import {useToast} from "../../../stories/contexts/toast/UseToast";
import {ToastStatus} from "../../../stories/components/toast/Toast";
import {createContestantAction, updateContestantAction} from "../../../store/slices/ContestantSlice";
import {genders} from "../../shared/Genders";

import '../../shared/LayoutForm.scss';
import "./ContestantForm.scss";

interface ContestantFormProps {
    editContestant: Contestant | null,
    resetForm: () => void
}

export const ContestantForm = ({editContestant, resetForm}: ContestantFormProps) => {
    const dispatch = useKarateDispatch();

    const dojo = useKarateSelector(state => state.dojos.dojo)
    const roles = useKarateSelector(state => state.roles.roles);
    const ranks = useKarateSelector(state => state.ranks.ranks);

    const toast = useToast();

    const formInputs = {
        firstName: 'firstName',
        lastName: 'lastName',
        gender: 'gender',
        dateOfBirth: 'dateOfBirth',
        weight: 'weight',
        ranks: 'ranks',
        roles: 'roles'
    }

    const {register, isFormValid, formState} = useForm({
        [formInputs.firstName]: {
            value: editContestant ? editContestant.firstName : null,
        },
        [formInputs.lastName]: {
            value: editContestant ? editContestant.lastName : null,
        },
        [formInputs.gender]: {
            value: editContestant ? genders.find(g => g.name.charAt(0).toLowerCase() === editContestant.gender.toLowerCase()) : genders[1],
        },
        [formInputs.dateOfBirth]: {
            value: editContestant ? editContestant.dateOfBirth : null,
        },
        [formInputs.weight]: {
            value: editContestant ? editContestant.weight : 0,
        },
        [formInputs.ranks]: {
            value: editContestant ? ranks
                .map(r => {
                    return {value: r.id, name: r.name}
                })
                .find(r => editContestant.rank.id === r.value) : null,
        },
        [formInputs.roles]: {
            value: editContestant ? roles.filter(r => editContestant.roles.find(rr => rr.id === r.id)).map(r => {
                return {value: r.id, name: r.name}
            }) : null,
        },
    }, {
        [formInputs.firstName]: [
            {validator: isNotNullOrEmpty, error: "Imie nie może być puste"},
            {validator: isLongerThan(1), error: "Imie musi być dłuższe od 1 znaka"},
            {validator: isShorterThan(50), error: "Imie musi być krótsze od 50 znaków"}
        ],
        [formInputs.lastName]: [
            {validator: isNotNullOrEmpty, error: "Nazwisko nie może być puste"},
            {validator: isLongerThan(1), error: "Nazwisko musi być dłuższe od 1 znaka"},
            {validator: isShorterThan(50), error: "Nazwisko musi być krótsze od 50 znaków"}
        ],
        [formInputs.weight]: [
            {validator: isGreaterThan(20), error: "Waga musi być większa od 20 kg"},
        ],
        [formInputs.dateOfBirth]: [
            {validator: isRequired, error: "Data urodzenia nie może być pusta"}
        ],
        [formInputs.ranks]: [
            {validator: isRequired, error: "Stopień musi być wybrany"},
        ],
        [formInputs.roles]: [
            {validator: isNotEmptyCollection, error: "Co najmniej jedna rola musi zostać wybrana"}
        ]
    });

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const canSave = isFormValid && addRequestStatus === 'idle';

    async function createContestant() {
        if (!canSave) return;

        try {
            setAddRequestStatus('pending');

            const contestant = getContestant();
            await dispatch(createContestantAction({dojoId: dojo!.id, contestant: contestant})).unwrap();

            resetForm();
            toast.open('Sukces', 'Uczestnik pomyślnie dodany');
        } catch (e: any) {
            toast.open('Failed', e.message, ToastStatus.Failed);
        } finally {
            setAddRequestStatus('idle');
        }
    }

    async function updateUser() {
        if (!canSave) return;

        try {
            setAddRequestStatus('pending');

            const contestant = getContestant();
            await dispatch(updateContestantAction({id: editContestant!.id, contestant: contestant})).unwrap();

            resetForm();
            toast.open('Sukces', 'Uczestnik pomyślnie zmodyfikowany');
        } catch (e: any) {
            toast.open('Failed', e.message, ToastStatus.Failed);
        } finally {
            setAddRequestStatus('idle');
        }
    }

    function getContestant() {
        const contestant: ContestantCreateModel = {
            firstName: formState[formInputs.firstName]?.value,
            lastName: formState[formInputs.lastName]?.value,
            gender: formState[formInputs.gender]?.value.name.charAt(0).toUpperCase(),
            weight: formState[formInputs.weight]?.value,
            dateOfBirth: formState[formInputs.dateOfBirth]?.value.toDateString(),
            rankId: formState[formInputs.ranks]?.value.value,
            roles: formState[formInputs.roles]?.value.map((r: { value: number; }) => r.value),
        }

        return contestant;
    }

    return (
        <div className='crud-form'>
            <div className='crud-title'>
                <h1>Dodaj uczestnika</h1>
            </div>

            <div className={'input-group'}>
                <Input label={'Imie'} placeholder={'Wprowadź imie...'}
                       {...register(formInputs.firstName)}></Input>
                <Input label={'Nazwisko'} placeholder={'Wprowadź nazwisko...'}
                       {...register(formInputs.lastName)}></Input>
                <InputRadio label={'Płeć'} choices={genders}
                            {...register(formInputs.gender)}></InputRadio>
                <InputDate label={'Data urodzenia'}
                           {...register(formInputs.dateOfBirth)}></InputDate>
                <InputRange label={'Waga'} initial={0} min={0} max={150} unit={'kg'}
                            {...register(formInputs.weight)}></InputRange>
                <InputDropdown label={'Stopień'} placeholder={'Wybierz stopień...'} choices={ranks.map(r => {
                    return {value: r.id, name: r.name}
                })} {...register(formInputs.ranks)}></InputDropdown>
                <InputMultiselect label={'Role'} placeholder={'Wybierz role...'} choices={roles.map(r => {
                    return {value: r.id, name: r.name}
                })} {...register(formInputs.roles)}></InputMultiselect>
                <div className='add-new-button'>
                    <Button name={'add-button'} text={editContestant ? 'Zmień' : 'Dodaj'}
                            onButtonClicked={editContestant ? updateUser : createContestant}
                            disabled={!canSave}></Button>
                </div>
            </div>
        </div>
    );
};
