import React from 'react';
import './Loader.scss';


export const Loader = () => {
        return (
            <div className={'loader'}></div>
        );
    }
;
