import React, {FormEvent, useRef, useState} from 'react';
import './InputRange.scss';
import {FormInput} from "../../shared/form/Form";
import {getClientX, preventDefault} from "../../shared/Helpers";

interface InputRangeProps extends FormInput<number> {
    name: string;
    label: string;
    initial: number;
    min: number;
    max: number;
    unit: string;
    immediateUpdate?: boolean;
}

export const InputRange = ({
                               name,
                               label,
                               initial,
                               min,
                               max,
                               unit,
                               onValueChanged,
                               resetFunctionRegistar,
                               populateFunctionRegistar,
                               errors,
                               immediateUpdate = false,
                           }: InputRangeProps) => {
    const [isMoving, setIsMoving] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [currentValue, setCurrentValue] = useState(toLocal(initial)); // percentage

    const rangeRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    React.useEffect(() => {

        function handleClickOutside(event: MouseEvent | TouchEvent) {
            if (isMoving) {
                preventDefault(event);
                let newPercentage = (getClientX(event) - offsetX) / (rangeRef.current!.scrollWidth);
                newPercentage = currentValue + newPercentage;
                if (newPercentage < 0) newPercentage = 0;
                if (newPercentage > 1) newPercentage = 1;
                setCurrentValue(newPercentage);
                inputRef.current!.value = '' + toAbsolute(newPercentage);

                if (immediateUpdate) onValueChanged(name, toAbsolute(newPercentage));
            }
        }

        function mouseUp() {
            setIsMoving(false);

            if (!immediateUpdate) onValueChanged(name, inputRef.current!.valueAsNumber);
        }

        if (isMoving) {
            document.addEventListener("mousemove", handleClickOutside);
            document.addEventListener("mouseup", mouseUp);

            document.addEventListener("touchmove", handleClickOutside);
            document.addEventListener("touchend", mouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", handleClickOutside);
            document.removeEventListener("mouseup", mouseUp);

            document.removeEventListener("touchmove", handleClickOutside);
            document.removeEventListener("touchend", mouseUp);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoving]);

    React.useEffect(() => {
        if (resetFunctionRegistar) resetFunctionRegistar(name, reset);
        if (populateFunctionRegistar) populateFunctionRegistar(name, populate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function populate(value: number) {
        setCurrentValue(toLocal(value));
        inputRef.current!.value = '' + value;
    }

    function reset() {
        setIsMoving(false);
        setOffsetX(0);
        setCurrentValue(toLocal(initial));

        inputRef.current!.value = '' + initial;
    }

    // to percentage
    function toLocal(v: number) {
        return (v - min) / (max - min);
    }

    // to absolute
    function toAbsolute(v: number) {
        return min + Math.round(v * (max - min));
    }

    function mouseDown(event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) {
        setOffsetX(getClientX(event.nativeEvent));
        setIsMoving(true);
    }

    function radioValueChanged(event: FormEvent<HTMLInputElement>) {
        let v = Number(event.currentTarget.value);
        if (isNaN(v)) v = initial;
        if (v > max) v = max;
        if (v < min) v = min;

        inputRef.current!.value = '' + v;
        setCurrentValue(toLocal(v));
        onValueChanged(name, v);
    }

    const err = errors?.map(e => (<div key={'err-' + e} className='input-error'>{e}</div>));

    return (
        <div className='input-range'>
            <div className='range-slider-wrapper'>
                <label htmlFor={name}>{label}</label>

                <div ref={rangeRef} className={'input-range-track'} onMouseDown={mouseDown} onTouchStart={mouseDown}>
                    <div className={'input-range-track-lower'} style={{width: '' + (currentValue * 100) + '%'}}></div>
                    <div ref={thumbRef} className={'input-range-thumb'}></div>
                    <div className={'input-range-track-upper'}></div>
                </div>

                <div className='input-range-slider'>
                    <input ref={inputRef} className='input' type="number"
                           defaultValue={initial}
                           onBlur={radioValueChanged}/>
                    <label>{unit}</label>
                </div>

            </div>
            {err}
        </div>
    );
};
