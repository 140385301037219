import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import {apiUrl} from "../../environment";
import {Role} from "../../shared/models/role";

export const fetchRolesAction = createAsyncThunk('role/get-list', async () => {
    const response = await axios.get<Role[]>(`${apiUrl}/role`);
    return response.data;
})

export interface RoleState {
    roles: Role[];
    status: string;
}

const initialState: RoleState = {
    roles: [],
    status: 'idle'
};

export const roleSlice = createSlice({
        name: 'ranks',
        initialState,
        reducers: {
            resetRoleState: (state, action: PayloadAction) => {
                state.roles = initialState.roles;
                state.status = initialState.status;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(fetchRolesAction.pending, (state, action) => {
                    state.status = 'loading';
                })
                .addCase(fetchRolesAction.fulfilled, (state, action) => {
                    state.roles = action.payload;
                    state.status = 'succeeded';
                })
                .addCase(fetchRolesAction.rejected, (state, action) => {
                    state.status = 'failed';
                })
        }
    }
);

export const {resetRoleState} = roleSlice.actions;
