import React, {useRef} from 'react';
import './Input.scss';
import {FormInput} from "../../shared/form/Form";


interface InputProps extends FormInput<string> {
    label: string;
    name: string;
    placeholder?: string;
    immediateUpdate?: boolean
    type?: string;
}

export const Input = ({
                          name,
                          label,
                          placeholder = '',
                          onValueChanged,
                          resetFunctionRegistar,
                          populateFunctionRegistar,
                          errors,
                          disabled,
                          immediateUpdate = false,
                          type = 'text',
                      }: InputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (resetFunctionRegistar) resetFunctionRegistar(name, reset);
        if (populateFunctionRegistar) populateFunctionRegistar(name, populate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function populate(value: string) {
        inputRef.current!.value = value;
    }

    function reset() {
        inputRef.current!.value = '';
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (immediateUpdate) {
            onValueChanged(name, event.target.value);
        }
    }

    const err = errors?.map(e => (<div key={'err-' + e} className='input-error'>{e}</div>));

    return (
        <div className='input'>
            <label htmlFor={name}>{label}</label>
            <input ref={inputRef} name={name} placeholder={placeholder} type={type} autoComplete='off'
                   disabled={disabled} onChange={onChange}
                   onBlur={(e) => onValueChanged(name, e.target.value)}/>
            {err}
        </div>
    );
};
