import React, {useMemo, useState} from 'react';
import {createPortal} from 'react-dom';

import {ToastContext} from './ToastContext';
import {IToast, Toast, ToastStatus} from "../../components/toast/Toast";
import {Guid} from "../../shared/Guid";

export const ToastProvider = (props: any) => {
    const [toasts, setToasts] = useState<IToast[]>([]);
    const open = (title: string, message: string, status: ToastStatus = ToastStatus.Succeed) => {
        setToasts((currentToasts) => [
            ...currentToasts,
            {id: Guid.newGuid(), title, message, status},
        ]);
    }

    const close = (id: string) =>
        setToasts((currentToasts) =>
            currentToasts.filter((toast) => toast.id !== id)
        );

    const contextValue = useMemo(() => ({open}), []);

    return (
        <ToastContext.Provider value={contextValue}>
            {props.children}

            {
                createPortal(
                    <div className="toaster">
                        {
                            toasts.map((toast) => (
                                    <Toast key={toast.id} toast={toast} close={() => close(toast.id)}/>
                                )
                            )
                        }
                    </div>,
                    document.body
                )
            }
        </ToastContext.Provider>
    );
};

