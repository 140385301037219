import React, {useEffect, useState} from 'react';
import './Table.scss';
import {iconArrowUpDown, iconDelete, iconEdit} from "../../icons/Icons";

export enum SortingOrder {
    Asc = 1,
    Desc = -1
}

export interface ColumnDefinition {
    title: string;
    name: string;
    columnStyle?: React.CSSProperties;
    template?: ((id: any, value: any) => JSX.Element)
    sort?: (v1: any, v2: any, order: SortingOrder) => number
}

export interface TableProps {
    columns: ColumnDefinition[];
    idColumn: string;
    data: object[];
    canHoverRow?: boolean

    actionsColumnStyle?: React.CSSProperties;
    actionsCellStyle?: React.CSSProperties;

    onRowEdit?: ((id: any) => void) | null,
    onRowDelete?: ((id: any) => void) | null,
    onRowDoubleClick?: ((id: any) => void) | null,

    editIcon?: JSX.Element,
    deleteIcon?: JSX.Element

    noDataMessage?: string

    defaultSortColumn?: string
    defaultSortOrder?: SortingOrder
}

export const Table = (props: TableProps) => {
    const {
        columns,
        idColumn,
        actionsColumnStyle = {},
        actionsCellStyle = {},
        editIcon = iconEdit,
        deleteIcon = iconDelete,
        canHoverRow = true,
        onRowEdit,
        onRowDelete,
        onRowDoubleClick,
        noDataMessage = 'No data',
        defaultSortColumn,
        defaultSortOrder = SortingOrder.Desc,
    } = props;

    const [data, setData] = useState(props.data);
    const [sortColumn, setSortColumn] = useState<{ name?: string, order: SortingOrder }>({
        name: defaultSortColumn,
        order: defaultSortOrder
    });

    useEffect(() => {
        if (!sortColumn.name) return;

        const column = columns.find(c => c.name === sortColumn.name)!;
        const sortedData = [...data.sort((a: any, b: any) => column.sort!(a[column.name], b[column.name], sortColumn.order))];

        setData(sortedData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortColumn]);

    function rowEdit(id: any) {
        if (onRowEdit) onRowEdit(id);
    }

    function rowDelete(id: any) {
        if (onRowDelete) onRowDelete(id);
    }

    function rowDoubleClick(id: any) {
        if (onRowDoubleClick) onRowDoubleClick(id);
    }

    function sortByColumn(columnName: string) {
        const column = columns.find(c => c.name === columnName)!;
        if (!column.sort) return;

        const currentOrder = sortColumn.name === columnName ? sortColumn.order : SortingOrder.Desc;
        const order = currentOrder === SortingOrder.Asc ? SortingOrder.Desc : SortingOrder.Asc;

        setSortColumn({name: columnName, order});
    }

    const colDefs = columns.map(c => (
        <col key={'col-def-' + c.title} style={c.columnStyle}/>
    ));

    colDefs.push(<col key={'col-def-actions'} style={actionsColumnStyle}/>)

    const tableHead = columns.map(c => (
        <th key={'th-' + c.title} className={c.sort ? 'sortable' : ''}
            onClick={() => sortByColumn(c.name)}>
            <div className='th-content-wrapper'>
            <span>
                {c.title}
            </span>
                {
                    c.name === sortColumn?.name &&
                    iconArrowUpDown(sortColumn.order === SortingOrder.Asc, true)
                }
            </div>
        </th>
    ));

    const actions = (id: any) => {
        return (
            <td key={'td-actions-' + id} style={actionsCellStyle}>
                <div className='table-actions'>
                    <div className='table-action table-action-update' onClick={() => rowEdit(id)}>
                        {editIcon}
                    </div>

                    <div className='table-action table-action-delete' onClick={() => rowDelete(id)}>
                        {deleteIcon}
                    </div>
                </div>
            </td>
        )
    }

    const rows = data.map(d => {
        const cols: JSX.Element[] = [];
        const id = d[idColumn as keyof typeof d];

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const value = d[column.name as keyof typeof d];
            if (column.template) {
                cols.push(column.template(id, value));
            } else {
                cols.push(<td key={column.name + id}>{value}</td>);
            }
        }

        cols.push(actions(id));

        return (
            <tr key={id}
                className={'table-row ' + (canHoverRow ? 'row-hoverable ' : ' ') + (onRowDoubleClick ? 'row-clickable ' : ' ')}
                onDoubleClick={() => rowDoubleClick(id)}>{cols}</tr>
        )
    });

    return (
        <div className='table'>

            <table>
                <colgroup>
                    {colDefs}
                </colgroup>

                <thead>
                <tr>
                    {tableHead}
                    <th></th>
                </tr>
                </thead>

                <tbody>
                {rows}
                </tbody>

            </table>
            <div className='table-no-data'>{noDataMessage}</div>
        </div>
    );
};
