import React, {FormEvent, useState} from 'react';
import './InputDropdown.scss';
import {Choice, FormInput} from "../../shared/form/Form";

interface DropdownProps extends FormInput<Choice | null> {
    name: string;
    label: string;
    placeholder?: string;
    choices: Choice[];
}

export const InputDropdown = ({
                                  name,
                                  label,
                                  placeholder = '',
                                  choices,
                                  onValueChanged,
                                  resetFunctionRegistar,
                                  populateFunctionRegistar,
                                  errors,
                                  disabled
                              }: DropdownProps) => {

    const [dropdownItems, setDropdownItems] = useState<Choice[]>(choices);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [inputValue, setInputValue] = useState('');

    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const inputIconRef = React.useRef<HTMLDivElement>(null);
    const listRef = React.useRef<HTMLUListElement>(null);

    React.useEffect(() => {

        if (inputRef.current) {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }

        function handleClickOutside(event: MouseEvent) {
            const target = event.target as Node;
            if (!inputIconRef.current?.contains(target) && !dropdownRef.current?.contains(target)) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

    }, [dropdownRef, inputRef, inputValue, cursorPosition, isDropdownOpen]);

    React.useEffect(() => {
        if (resetFunctionRegistar) resetFunctionRegistar(name, reset);
        if (populateFunctionRegistar) populateFunctionRegistar(name, populate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function populate(choice: Choice | null) {
        if (choice) {
            setInputValue(choice?.name ?? '');
            selectChoice(choice.value);
        }
    }

    function reset() {
        deselectAll();
        setDropdownItems(choices);
        setIsDropdownOpen(false);
        setCursorPosition(0);
        setInputValue('');
    }

    function deselectAll() {
        const ul = listRef.current;
        const children = ul?.children;

        for (let i = 0; i < (children?.length ?? 0); i++) {
            const li = children?.item(i) as HTMLLIElement;
            li?.classList.remove('selected');
        }
    }

    function selectChoice(value: number | string) {
        const ul = listRef.current;
        const children = ul?.children;

        deselectAll();

        for (let i = 0; i < (children?.length ?? 0); i++) {
            const li = children?.item(i) as HTMLLIElement;
            if (li.value === value) li.classList.add('selected');
        }
    }

    function filterDropdownItems(prefix: string) {
        const suggestedChoices = choices.filter(c => c.name.toLowerCase().startsWith(prefix.toLowerCase()));
        setDropdownItems(suggestedChoices);
        return !!suggestedChoices.length;
    }

    function choiceChanged(event: FormEvent<HTMLUListElement>) {
        const target = event.target as HTMLLIElement;
        if (!target) return;

        const choice = choices.find(c => c.value === target.value);
        setInputValue(choice?.name ?? '');
        selectChoice(target.value);
        setIsDropdownOpen(false);
        onValueChanged(name, choice ?? null);
    }

    function inputChanged(event: FormEvent<HTMLInputElement>) {
        const currentTarget = event.currentTarget;
        const cursorPosition = currentTarget.selectionStart || 0;
        setCursorPosition(cursorPosition);
        const beforeCursor = currentTarget.value.substring(0, cursorPosition);
        const oldValue = inputValue;

        deselectAll();
        setInputValue(beforeCursor);

        const found = filterDropdownItems(beforeCursor);
        setIsDropdownOpen(found);

        // user deleted part of input
        if (currentTarget.value.length < oldValue.length) return;

        // autosuggestion
        const choice = choices.find(c => c.name.toLowerCase().startsWith(beforeCursor.toLowerCase()))
        if (!choice) return;

        selectChoice(choice.value);
        setInputValue(choice.name);
    }

    function inputLeaved(event: FormEvent<HTMLInputElement>) {
        setIsDropdownOpen(false);
        const choice = choices.find(c => c.name === event.currentTarget.value.trim());

        if (!choice) {
            setInputValue('');
            deselectAll();
            onValueChanged(name, null);
            return;
        }

        selectChoice(choice.value);
        setInputValue(choice.name);
        onValueChanged(name, choice);
    }

    function toggleDropdown(event: FormEvent<HTMLDivElement>) {
        if (!isDropdownOpen) {
            filterDropdownItems('');
        }

        setIsDropdownOpen(!isDropdownOpen);
    }

    const list = dropdownItems.map(c => {
        return (
            <li key={c.value} value={c.value}>{c.name}</li>
        )
    });

    const err = errors?.map(e => (<div key={'err-' + e} className='input-error dropdown-error'>{e}</div>));

    return (
        <div className={'input dropdown ' + (isDropdownOpen ? 'dropdown-open' : 'dropdown-closed')}
             data-disabled={disabled}>
            <label htmlFor={name}>{label}</label>
            <div className='input-with-icon'>
                <input name={name} type='text' ref={inputRef} value={inputValue} onChange={inputChanged}
                       disabled={disabled}
                       onBlur={inputLeaved}
                       autoComplete='off' spellCheck='false'
                       placeholder={placeholder}/>
                <div ref={inputIconRef} className='input-icon' onClick={toggleDropdown}
                     onMouseDown={(e) => e.preventDefault()}>
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.125 20.875C17.3028 20.875 21.5 16.6778 21.5 11.5C21.5 6.32219 17.3028 2.125 12.125 2.125C6.94719 2.125 2.75 6.32219 2.75 11.5C2.75 16.6778 6.94719 20.875 12.125 20.875Z"
                            stroke="#D9D9D9" strokeWidth="1.40625" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path className='dropdown-arrow'
                              d="M8.8156 10.3187L12.125 13.6187L15.4343 10.3187"
                              stroke="#D9D9D9"
                              strokeWidth="1.40625"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
            {err}
            <div ref={dropdownRef} className="dropdown-choices-wrapper">
                <div className='dropdown-choices'>
                    <ul ref={listRef} onClick={choiceChanged}
                        onMouseDown={(e) => e.preventDefault()}>
                        {list}
                    </ul>
                </div>
            </div>
        </div>
    );
};
