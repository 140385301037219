import React from 'react';
import './Nav.scss';
import {useNavigate} from "react-router-dom";

interface NavProps {
    title: string,
    logo?: string,
    children?: JSX.Element[] | JSX.Element
}

export const Nav = ({title, logo, children}: NavProps) => {
    const navigate = useNavigate();

    function redirect() {
        navigate("/");
    }

    return (
        <nav className='nav'>
            <div className='logo-wrapper nav-item' onClick={redirect}>
                {
                    !!logo ??
                    <div className='logo'><img src={logo} alt="Logo"/></div>
                }
                <div className='title'>{title}</div>
            </div>
            <div className='nav-item'>{children}</div>
        </nav>
    );
};
